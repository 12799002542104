'use strict'

angular.module('mvpcarrefourApp')
    .directive('product', ['$state', '$rootScope', '$templateCache',
        ($state, $rootScope, $templateCache) => {

          var directive = {
            restrict: 'E',
            template: $templateCache.get('app/product/product.tpl.html'),
            replace: true,
            scope: {
              product: "="
            },
            link: (scope, elem, attrs) => {
              // Mobile:  Aseguramos la eliminacion del Scroll Hide
              angular.element(document).find("body").removeClass('scrollHide')

              // Clase active
              scope.setActive = function(product) {
                scope.activeItem = product.slug == scope.activeItem ?
                  null : product.slug;
              }

              // Chekeo del producto votado
              scope.checkIfVoted = function(productId) {
                return _.find($rootScope.votes, function(v) {
                  return v.product._id === productId;
                }) ? true : false;
              }
            }
          };

          var template = $templateCache.get('app/product/product.tpl.html');
          //Si estamos en DEV la cache de templates no se usa, y se referencia la template directamente
          //por la URL, en produccion se utiliza la cache
          if (template == null) {
            directive.templateUrl = 'app/product/product.tpl.html';
          } else {
            directive.template = template
          }

          return directive;
        }
    ])
    .directive('productDetail', ['$state', '$rootScope', '$uibModal', '$location', 'Vote', 'Auth', '$sce', '$window', 'Modal', 'User', '$templateCache',
        ($state, $rootScope, $uibModal, $location, Vote, Auth, $sce, $window, Modal, User, $templateCache) => {

          var directive =  {
                restrict: 'E',
                // replace: true,
                scope: {
                    product: "="
                },
                link: (scope, elem, attrs) => {

                    // Para version mobile, controlamos el scroll al posicionarse la  ficha del producto abierta
                    if (angular.element($window).width() < 768 && angular.element(document).find("#grid-panel-content")) {
                        angular.element(document).find("body").addClass('scrollHide')
                    } else {
                        angular.element(document).find("body").removeClass('scrollHide')
                    }


                    // Video:
                    //scope.video = product.urls.media.video;
                    scope.trustSrc = function(video) {
                        return $sce.trustAsResourceUrl(video);
                    }


                    // clase active
                    scope.setActive = function(product) {
                        scope.activeItem = product.slug == scope.activeItem ?
                            null : product.slug;
                    }


                    //Chekeo producto votado
                    scope.checkIfVoted = function(productId) {
                        return _.find($rootScope.votes, function(v) {
                            return v.product._id === productId;
                        }) ? true : false;
                    }

                    scope.getLifeStyle = function(prod, value) {
                        let vote = _.find($rootScope.votes, function(x) {
                            return x.product._id === prod;
                        })

                        if (angular.isUndefined(vote)) return false
                        return vote.target == value;
                    }

                    //Chekeo fin de votacion
                    scope.checkEndVote = function() {
                        return $rootScope.totalVoteUser == 15;
                    }

                    scope.shareIframe = function() {

                        var widgetUrl = _.replace($location.$$absUrl, 'votacion', 'widget') + '/';


                        (Modal.confirm.widget({
                            title: "Añade este producto a tu Web",
                            widgetUrl: widgetUrl
                        }, function(err) {
                           // console.log(err)
                        }))()
                    }

                    //Gestion del voto
                    scope.vote = function(id, name, product) {

                        // Si no es un usuario logado, redirigimos al login
                        if (!Auth.isLoggedIn()) {

                            //Guardamos la intencion de voto para recuperarla despues de autenticar al usuario
                            $rootScope.votePreLogin = {
                                "product": product,
                                "category": scope.$parent.$parent.$parent.$parent.selectedCategory.slug,
                                "fromState": "product"
                            }
                            return $state.go('login')
                        };

                        //Envio del voto
                        if (!scope.checkIfVoted(id) && !scope.checkEndVote()) {

                            let _vote = {
                                "user": $rootScope.currentUser._id,
                                "product": id,
                                "target": scope.vote.target
                            };
                            Vote.vote(_vote).then(result => {

                                product.categories[0]=product.categories[0]._id;
                                _vote.product = product;
                                _vote._id=result.id;
                                $rootScope.votes.push(_vote);
                                $rootScope.totalVoteUser = result.votes;
                                if (result.votes == 15) User.voteCompleted({ email: $rootScope.currentUser.email });

                                // Pendiente de cambiar este modal al sevicio para evitar la manipulacion de HTML en el controller
                                $uibModal.open({
                                    animation: false,
                                    ariaLabelledBy: 'modal-title-bottom',
                                    ariaDescribedBy: 'modal-body-bottom',
                                    templateUrl: 'app/product/modal-summary-vote.html',
                                    size: 'md',
                                    controller: 'ContinueModalCtrl',
                                    resolve: {
                                        prod: function() {
                                            return product;
                                        },
                                        completedUrl: function() {
                                            return true;
                                        },
                                        lifeStyleOption: function(){
                                            return scope.vote.target == null ? true : false;
                                        },
                                        idVote : function() {
                                            return result.id;
                                        }
                                    }

                                });

                            });

                            // Asegurar que el destroy de la intencion de voto, en el caso de que hubiera
                            if (!angular.isUndefined($rootScope.votePreLogin)) {
                                delete $rootScope.votePreLogin;
                            }
                        }
                    }

                    //Añadimos funcionalidad al boton de cierre de la directiva original ng-grid
                    let ax = angular.element(document).find(".close-x");
                    ax.bind('click', () => {
                        angular.element(document).find("body").removeClass('scrollHide')
                    });

                }
            }

          var template = $templateCache.get('app/product/product-detail.tpl.html');
          //Si estamos en DEV la cache de templates no se usa, y se referencia la template directamente
          //por la URL, en produccion se utiliza la cache
          if (template == null) {
            directive.templateUrl = 'app/product/product-detail.tpl.html';
          } else {
            template: template
          }

          return directive;

        }
    ])
